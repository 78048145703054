<template>
  <div v-if="!loadingEntities" class="select-entity-container">
    <FriedParagraph class="branding">{{ $t('login') }}</FriedParagraph>
    <FriedH2 class="title branding">{{ $t('select-entity') }}</FriedH2>
    <FriedMessage
      v-if="error"
      data-external="select-entity-error-div"
      class="error"
      :type="MessageType.Error"
      :title="$t(error.title)"
    >
      <i18n-t :keypath="error.description">
        <FriedLink
          :normal="true"
          place="contact-support-link"
          href="http://help.getaccept.com/"
          target="_blank"
        >
          {{ $t('contact-support') }}
        </FriedLink>
      </i18n-t>
    </FriedMessage>
    <FriedH5 class="entity-label">{{ $t('entities') }}</FriedH5>
    <FriedSearchInput
      v-if="showSearch"
      v-model="searchInput"
      class="entites-search"
      :data-external="'login-select-entity-search-box-input'"
      :placeholder="$t('search')"
    >
      <template #left>
        <FriedIcon icon="search" :size="IconSize.Small" />
      </template>
    </FriedSearchInput>
    <div class="select-entity-selector" role="radiogroup">
      <div
        v-for="entity in filteredEntities"
        :key="entity.id"
        v-wave
        role="radio"
        :data-external="`login-select-entity:${entity.id}-list-item`"
        class="entity-radio"
        tabindex="0"
        :class="{ checked: checked(entity.id) }"
        :aria-checked="checked(entity.id)"
        @click="selectEntity(entity.id)"
        @dblclick="selectAndSubmit(entity.id)"
        @keydown.enter="selectEntity(entity.id)"
        @keydown.space="selectEntity(entity.id)"
      >
        <FriedParagraph class="entity-title">{{ entity.name }}</FriedParagraph>
        <FriedParagraph class="entity-plan" :size="ParagraphSize.Small">
          {{ getFormattedPlanName(entity.plan as SubscriptionPlan) }}
        </FriedParagraph>
      </div>
    </div>
    <FriedButton
      data-external="select-entity-submit-button"
      :loading="loadingEntity"
      :disabled="!selectedEntityId"
      class="submit"
      @click="submit"
    >
      {{ $t('select-entity-submit-button') }}
    </FriedButton>
    <FriedLink :href="createEntityUrl">{{ $t('create-new-entity') }}</FriedLink>
  </div>
</template>

<script lang="ts">
/* eslint-disable no-restricted-syntax */
import { ParagraphSize, MessageType, IconSize } from '@getaccept/fried-tofu';
import type { AxiosError } from 'axios';
import type { Ref } from 'vue';
import { onMounted, computed, defineComponent, ref } from 'vue';
import type { SelectEntity as ISelectEntity } from '@getaccept/lib-shared/src/select-entity/types/select-entity';
import { useRoute, useRouter } from 'vue-router';
import { SubscriptionPlan } from '@getaccept/lib-shared/src/enums/subscription-plan';
import bugsnagClient from '@getaccept/lib-shared/src/bugsnag';
import type { ErrorMessage } from '../../types/error-message';
import { SelectEntityService } from '../../api/select-entity/services/select-entity.service';
import { getErrorMessage } from '../../helpers/error-message';
import { ErrorKey } from '../../types/enums/error-key';
import { UserService } from '../../api/user/user.service';
import { t } from '../../helpers/translation';

export default defineComponent({
  setup() {
    const router = useRouter();
    const route = useRoute();
    const selectedEntityId = ref(null);
    const searchInput = ref('');
    const loadingEntities = ref(false);
    const loadingEntity = ref(false);
    const entities: Ref<ISelectEntity[]> = ref([]);
    const error: Ref<ErrorMessage> = ref(null);
    let userId = '';
    let goUrl = '';
    const showSearch = computed(() => entities.value?.length > 3);
    const createEntityUrl = computed(() => `/create-entity/${userId}`);
    const filteredEntities = computed(() =>
      searchInput.value
        ? entities.value.filter((entity: ISelectEntity) =>
            [entity.name].some((queryableProperty: string) =>
              queryableProperty.toLowerCase().includes(searchInput.value.toLowerCase())
            )
          )
        : entities.value
    );
    const loadEntities = async () => {
      try {
        loadingEntities.value = true;
        entities.value = await SelectEntityService.entities();
      } catch (e) {
        loadEntitiesFail(e);
      } finally {
        loadingEntities.value = false;
      }
    };
    const loadEntitiesFail = async (e: AxiosError) => {
      if (e?.request?.status === 401) {
        if (goUrl) {
          router.push({ name: 'login', query: { go: goUrl } });
          return;
        }
        router.push({ name: 'login' });
        return;
      }
      if (bugsnagClient) {
        bugsnagClient.notify(e);
      }
      error.value = getErrorMessage(ErrorKey.Unknown);
    };
    const loadEntity = async (entityId: string) => {
      loadingEntity.value = true;
      try {
        await SelectEntityService.selectEntity(entityId);
        localStorage.setItem('GA_ACTIVE_ENTITY', entityId);
        setTimeout(() => {
          window.open(goUrl || '/', '_self');
        }, 1300);
      } catch (e) {
        loadingEntity.value = false;
        if (bugsnagClient) {
          bugsnagClient.notify(e);
        }
        error.value = getErrorMessage(ErrorKey.LoadEntity);
      }
    };
    const authEntity = async ({ id, auth }: ISelectEntity) => {
      const go: string = goUrl ? `?go=${goUrl}` : '';

      if (auth === 'saml') {
        try {
          const samlUrl = await SelectEntityService.getSamlUrl(id);
          window.open(`${samlUrl}${go}`, '_self');
        } catch (e) {
          if (bugsnagClient) {
            bugsnagClient.notify(e);
          }
          error.value = getErrorMessage(ErrorKey.FetchSamlUrlFailed);
        }
        return;
      }

      window.open(`/auth/${auth}/${id}${go}`, '_self');
    };
    const loadUserId = async () => {
      try {
        userId = await UserService.loadUserId();
      } catch (e) {
        error.value = getErrorMessage(ErrorKey.FetchIdFailed);
      }
    };
    const checked = (id: string): boolean => selectedEntityId.value === id;
    const setGoUrl = () => {
      goUrl = `${route.query.go || ''}`;
    };
    const selectEntity = (id: string) => {
      selectedEntityId.value = id;
    };
    const selectAndSubmit = (id: string) => {
      selectedEntityId.value = id;
      submit();
    };
    const submit = () => {
      const selectedEntity = entities.value.find(
        (entity: ISelectEntity) => entity.id === selectedEntityId.value
      );
      if (selectedEntity.auth) {
        authEntity(selectedEntity);
        return;
      }
      loadEntity(selectedEntityId.value);
    };
    const getFormattedPlanName = (plan: SubscriptionPlan) => {
      switch (plan) {
        case SubscriptionPlan.Esign:
          return t('esign-plan');
        case SubscriptionPlan.WebTrial:
          return t('webtrial-plan');
        case SubscriptionPlan.SalesTrial:
          return t('salestrial-plan');
        case SubscriptionPlan.Professional:
          return t('professional-plan');
        case SubscriptionPlan.Enterprise:
          return t('enterprise-plan');

        // Legacy plans
        case SubscriptionPlan.Free:
          return t('free-plan');
        case SubscriptionPlan.Essential:
          return t('essential-plan');
        case SubscriptionPlan.EnterprisePlus:
          return t('enterpriseplus-plan');
        case SubscriptionPlan.Trial:
          return t('trial-plan');
        case SubscriptionPlan.Solo:
          return t('solo-plan');
        case SubscriptionPlan.Starter:
          return t('starter-plan');
        case SubscriptionPlan.Business:
          return t('business-plan');
        case SubscriptionPlan.Pro:
          return t('pro-plan');
        case SubscriptionPlan.Partner:
          return t('partner-plan');
        default:
          return plan;
      }
    };
    onMounted(() => {
      setGoUrl();
      loadEntities();
      loadUserId();
    });
    return {
      selectedEntityId,
      searchInput,
      loadingEntities,
      loadingEntity,
      error,
      showSearch,
      filteredEntities,
      createEntityUrl,
      authEntity,
      loadEntity,
      checked,
      selectEntity,
      selectAndSubmit,
      ParagraphSize,
      submit,
      entities,
      MessageType,
      IconSize,
      getFormattedPlanName,
    };
  },
});
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: var(--spacing-150);
}

.error {
  margin-bottom: var(--spacing-150);
}

.select-entity-selector {
  min-height: 210px;
  height: 40vh;
  overflow: auto;
  margin-bottom: var(--spacing-150);
}

.select-entity-container {
  .margin-bottom {
    margin-bottom: var(--spacing-50);
  }

  .submit {
    width: 100%;
    margin-top: var(--spacing-50);
    margin-bottom: var(--spacing-150);
  }
}

.entites-search {
  margin-bottom: var(--spacing-100);
}

.entity-label {
  margin-bottom: var(--spacing-50);
  display: block;
}

.entity-radio {
  width: 100%;
  display: block;
  padding: 0.625rem;
  border-radius: var(--radius-medium);
  margin-bottom: var(--spacing-50);
  cursor: pointer;

  * {
    cursor: pointer;
  }

  &:hover {
    background-color: var(--gray-96);
  }

  &.checked {
    background-color: var(--gray-89);
  }

  .entity-title {
    display: block;
    line-height: 1rem;
  }

  .entity-plan {
    padding-top: var(--spacing-25);
  }
}
</style>
